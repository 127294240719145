import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Engineering Geology`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PZ2ZtdGNqeTE2WTA"
      }}>{`Download: Engineering Geology by F.G. Bell 2nd ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-POUUwSlhoZXc4ems"
      }}>{`Download: Engineering Geology for underground rocks by Suping Peng and Jincai Zhang  `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PdVRqRU12QXN4c1E"
      }}>{`Download: Foundations of engineering geology by Tony Walthan 3rd Ed.`}</a></p>
    <h2>{`Lectures`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/1-Minerals-1.ppt"
      }}>{`1 Minerals`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/2-minerals_II_jh-1.ppt"
      }}>{`minerals_II_jh`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/3-Igneous-Rocks-1.pptx"
      }}>{`3 Igneous Rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/4-Sedimentary-Rocks-1.ppt"
      }}>{`4 Sedimentary Rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/5-Metamorphic-rocks-1.pptx"
      }}>{`5 Metamorphic rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/basic-mechanism-of-slope-failure-1.pdf"
      }}>{`basic mechanism of slope failure`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Basics-of-structural-geology-1.docx"
      }}>{`Basics of structural geology`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Brunton_Compass-1.pdf"
      }}>{`Brunton_Compass`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Circular-Failure-1.pptx"
      }}>{`Circular Failure`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Cross-Sections-1.pdf"
      }}>{`Cross Sections`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Dams-and-Dam-Sites-1.docx"
      }}>{`Dams and Dam Sites`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Dams-and-Dam-Sites-1.pdf"
      }}>{`Dams and Dam Sites`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Earthquakes-1.pdf"
      }}>{`Earthquakes`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/engg-geology-1.pdf"
      }}>{`engg geology`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/engineering-classification-of-rock-materials-1.pdf"
      }}>{`engineering classification of rock materials`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Engineering-geology-of-tunnels-1-1.docx"
      }}>{`Engineering geology of tunnels 1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/engineering-geology-of-tunnels-1.pdf"
      }}>{`engineering geology of tunnels`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/factors-affecting-slope-failure-1.pdf"
      }}>{`factors affecting slope failure`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/folding-of-rocks-1.rtf"
      }}>{`folding of rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/folds-1.pdf"
      }}>{`folds`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Geological-Map-Interpretation-1.ppt"
      }}>{`Geological Map Interpretation`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Geological-structures.-1.pptx"
      }}>{`Geological structures.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/geology-1.docx"
      }}>{`geology`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Igneous-Rocks-1.docx"
      }}>{`Igneous Rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Igneous-Rocks-1.pdf"
      }}>{`Igneous Rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/igneous-rocks-1.pptx"
      }}>{`igneous rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Influence-of-Geological-Conditions-on-Design-and-Construction-of-Tunnels-1.pdf"
      }}>{`Influence of Geological Conditions on Design and Construction of Tunnels`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Influence-of-Geological-Conditions-on-Design-and-Construction-of-Tunnels1-1.pdf"
      }}>{`Influence of Geological Conditions on Design and Construction of Tunnels1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Introduction-to-engineering-geology-1.docx"
      }}>{`Introduction to engineering geology`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Introduction-to-engineering-geology-1.pdf"
      }}>{`Introduction to engineering geology`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/KinematicAnalysis-wedge-failure-1.pdf"
      }}>{`KinematicAnalysis, wedge failure`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Landslides-1.pdf"
      }}>{`Landslides`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/landslides1-1.pdf"
      }}>{`landslides1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/MEASUREMENT-AND-CHARACTERIZATION-OF-ROCK-MASS-JOINTING-1.pdf"
      }}>{`MEASUREMENT AND CHARACTERIZATION OF ROCK MASS JOINTING`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Measurements-of-and-Correlations-between-Block-Size-and-Rock-Quality-Designation-RQD-1.pdf"
      }}>{`Measurements of and Correlations between Block Size and Rock Quality Designation (RQD)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Metamorphic-rocks-1.docx"
      }}>{`Metamorphic rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Metamorphic-rocks-1.pdf"
      }}>{`Metamorphic rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Mineral-properties-1.pdf"
      }}>{`Mineral properties`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/mode-of-occurance-1.pptx"
      }}>{`mode of occurrence`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Plane-Failure-1.pptx"
      }}>{`Plane Failure`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Plate-Tectonics-1.pptx"
      }}>{`Plate Tectonics`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/plate_tectonics-1.pdf"
      }}>{`plate_tectonics`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Roc-slop-stability-1.pptx"
      }}>{`Roc slop stability`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Rock-Mechanics-pt1-1.ppt"
      }}>{`Rock Mechanics-pt1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/ROCK-QUALITY-DESIGNATION-RQD-1.pdf"
      }}>{`ROCK QUALITY DESIGNATION (RQD)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/ROCK-QUALITY-DESIGNATION.pdf"
      }}>{`ROCK QUALITY DESIGNATION`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Rock-Slope-Stability-Analysis.pptx"
      }}>{`Rock Slope Stability Analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Rock_mass_classification.pdf"
      }}>{`Rock_mass_classification`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/rock-mass-classification.pdf"
      }}>{`rock-mass-classification`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/RQD-Slope-stability-classification-Weathering.pdf"
      }}>{`RQD Slope stability classification Weathering`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/seafloor-spreading.pdf"
      }}>{`seafloor spreading`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/sedimentary-rocks.docx"
      }}>{`sedimentary rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/sedimentary-rocks.pdf"
      }}>{`sedimentary rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/sedimentary-rocks.pptx"
      }}>{`sedimentary rocks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/sedimentary-structres.pptx"
      }}>{`sedimentary structres`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/stereographic-projections.pdf"
      }}>{`stereographic projections`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Stereographic.pdf"
      }}>{`Stereographic`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Stereonet.pptx"
      }}>{`Stereonet`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/stereonets.pdf"
      }}>{`stereonets`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/strike-and-dip.pdf"
      }}>{`strike and dip`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/structural-geology.docx"
      }}>{`structural geology`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/structural-geology.pdf"
      }}>{`structural geology`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Subsidence.pptx"
      }}>{`Subsidence`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Terminology_of_-folds.ppt"
      }}>{`Terminology`}<em parentName="a">{`of`}</em>{` folds`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/The-Rock-Quality-Designation-RQD-in-Practice.pdf"
      }}>{`The Rock Quality Designation (RQD) in Practice`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/The-Usefulness-of-Rock-Quality-Designation-RQD-in.pdf"
      }}>{`The Usefulness of Rock Quality Designation (RQD) in`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Toppling-Planar-Sliding-Wedge-Sliding.pdf"
      }}>{`Toppling, Planar Sliding, Wedge Sliding`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/tunneling-1.docx"
      }}>{`tunneling`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/TUNNELS.ppt"
      }}>{`TUNNELS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Weathering-and-Erosion.pdf"
      }}>{`Weathering and Erosion`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Wedge-Failure-landslides.pptx"
      }}>{`Wedge Failure % landslides`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Wedge-Failure-_-landslides.1.pdf"
      }}>{`Wedge Failure _ landslides.1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Wedge-Failure.pptx"
      }}>{`Wedge Failure`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/What-is-the-difference-between-weathering-and-erosion-1.pdf"
      }}>{`What is the difference between weathering and erosion`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      